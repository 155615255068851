import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SelectFrente = ({
    value,
    onChange,
    label='Frente',
    name='Frente',
    error=false,
    helperText='',
    disabled=false,
    defaultValue=null,
    multiple=false
  }) => {
  const { frentes } = useStoreState(state => ({
    frentes: state.frentes
  }))
  const { getFrentes } = useStoreActions(actions => ({
    getFrentes: actions.getFrentes
  }))
  useEffect(() => {
    getFrentes()
    if (defaultValue) {
      onChange({target: {
        name: 'Frente',
        value: frentes.find(i => i.IdFrente === defaultValue)
      }})
    }
  }, [frentes, defaultValue])
  return <Autocomplete
    fullWidth
    options={frentes}
    disabled={disabled}
    getOptionLabel={option => option['Codigo']}
    multiple={multiple}
    renderInput={params => <TextField
      {...params}
      fullWidth
      error={error}
      label={label}
      margin="none"
      helperText={helperText}
    />}
    value={value}
    onChange={(ev, value) => onChange({target: {name, value}})}
  />
}

export default SelectFrente