import React, { useState, useEffect } from 'react';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import { DataGrid } from 'devextreme-react'
import { Column, FilterRow, Pager } from 'devextreme-react/data-grid'
import http from '../../Utils/http';
import dayjs from 'dayjs';

const HistorialMensajes = ({ handleClose, dataGIO }) => {
  const [mensajes, setMensajes] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await http.get(`/maestros/mensaje?CodigoMaquinaria=${dataGIO.codigo_maquinaria}`);
      setMensajes(data.map(i => ({
        ...i,
        FechaCreo: dayjs(i.FechaCreo).format('DD/MM/YY HH:mm'),
        FechaRecibio: i.FechaRecibio ? dayjs(i.FechaRecibio).format('DD/MM/YY HH:mm') : ''
      })));
    })();
  }, []);

  return (
    <>
      <DialogTitle>Historial de mensajes</DialogTitle>
      <DialogContent>
        <DataGrid
          dataSource={mensajes}
          showBorders
          showColumnLines
          rowAlternationEnabled
          columnAutoWidth
          noDataText="No hay mensajes"
          height="60vh"
        >
          <FilterRow visible={ true} />
          <Column caption="Mensaje" dataField="Mensaje" />
          <Column caption="Tipo" dataField="TipoMensaje" width={110} />
          <Column caption="Fecha envio" dataField="FechaCreo" />
          <Column caption="Fecha recibio" dataField="FechaRecibio" />
          <Column caption="Recibido" dataField="UsuarioRecibio" />
          <Pager visible={true} displayMode="full" showPageSizeSelector={true} showInfo={true} />
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </>
  );
}

export default HistorialMensajes;