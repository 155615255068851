import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import http from '../../Utils/http';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import LoadingButton from '../../components/Utils/LoadingButton';
import SelectFrente from '../../components/Selects/SelectFrente';
import { getIdentity } from '../../Utils/auth';
import SelectMaquinariaGIO from '../../components/Selects/SelectMaquinariaGIO';
import SelectTipoMaq from '../../components/Selects/SelectTipoMaq';

const ExportacionCrear = ({ onClose, onRefresh }) => {
  const [values, setValues] = useState({
    DBNombre: '',
    DBTabla: '',
    FechaInicia: '',
    FechaTermina: ''
  });
  const [filtros, setFiltros] = useState({
    frentes: [],
    maquinaria: [],
    tipo_maquinaria: []
  });
  const [errors, setErrors] = useState({});
  const [listaDB, setListaDB] = useState([]);
  const [listaTablas, setListaTablas] = useState([]);
  const [saving, setSaving] = useState(false);

  const getListaDB = async () => {
    const data = await http.get('/maestros/dataset/data_catalog');
    setListaDB(data);
  }

  const validarDatos = () => {
    const errorsTmp = {};
    if (!values.DBNombre) {
      errorsTmp.DBNombre = 'La base de datos es requerida';
    }
    if (!values.DBTabla) {
      errorsTmp.DBTabla = 'La tabla es requerida';
    }
    if (!values.FechaInicia) {
      errorsTmp.FechaInicia = 'La fecha de inicio es requerida';
    }
    if (!values.FechaTermina) {
      errorsTmp.FechaTermina = 'La fecha de fin es requerida';
    }
    if (values.FechaInicia && values.FechaTermina && dayjs(values.FechaInicia).isAfter(values.FechaTermina)) {
      errorsTmp.FechaInicia = 'La fecha de inicio no puede ser mayor a la fecha de fin';
    }
    setErrors(errorsTmp);
    return Object.keys(errorsTmp).length === 0;
  }
  const handleChange = (e) => {
    if (e.target.name === 'DBNombre') {
      const tablas = listaDB.find(i => i.db_tag === e.target.value).db_tablas || [];
      setListaTablas(tablas);
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  }
  const removeNullProperties = (obj) => {
    const tmp = { ...obj };
    Object.keys(tmp).forEach(key => (tmp[key] === null
      || tmp[key] === ''
      || (Array.isArray(tmp[key]) && !tmp[key].length)) && delete tmp[key]);
    return tmp;
  }
  const handleSave = async () => {
    if (validarDatos()) {
      try {
        setSaving(true);
        const dbname = listaDB.find(i => i.db_tag === values.DBNombre).db_nombre;
        const filtrosTMP = removeNullProperties(filtros);
        if (filtrosTMP.hasOwnProperty('frentes')) {
          filtrosTMP.frentes = filtrosTMP.frentes.map(i => i.Codigo);
        }
        if (filtrosTMP.hasOwnProperty('maquinaria')) {
          filtrosTMP.maquinaria = filtrosTMP.maquinaria.map(i => i.Codigo);
        }
        if (filtrosTMP.hasOwnProperty('tipo_maquinaria')) {
          filtrosTMP.tipo_maquinaria = filtrosTMP.tipo_maquinaria.map(i => i.toUpperCase());
        }
        await http.post('/maestros/dataset', {
          ...values,
          DBNombre: dbname,
          Filtros: filtrosTMP,
          UsuarioCreo: getIdentity()
        });
        onRefresh();
        onClose();
      } catch (error) {
        console.log(error)
        setSaving(false);
        toast.error('Error al guardar los datos');
      }
    }
  }

  useEffect(() => {
    getListaDB();
  }, []);

  return (
    <>
      <DialogTitle>Crear exportación</DialogTitle>
      <DialogContent>
        <FormControl
          fullWidth
          error={Boolean(errors.DBNombre)}
        >
          <InputLabel>Base de datos</InputLabel>
          <Select
            value={values.DBNombre}
            onChange={ev => handleChange({ target: { name: 'DBNombre', value: ev.target.value } }) }
          >
            {
              listaDB.map((i, idx) => <MenuItem key={idx} value={i.db_tag}>
                {i.db_label}
              </MenuItem>)
            }
          </Select>
          <FormHelperText>{errors.DBNombre}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={Boolean(errors.DBTabla)}
        >
          <InputLabel>Tablas</InputLabel>
          <Select
            value={values.DBTabla}
            onChange={ev => handleChange({ target: { name: 'DBTabla', value: ev.target.value } }) }
          >
            {
              listaTablas.map((i, idx) => <MenuItem key={idx} value={i.db_tabla_nombre}>
                {i.db_tabla_label}
              </MenuItem>)
            }
          </Select>
          <FormHelperText>{errors.DBTabla}</FormHelperText>
        </FormControl>
        <TextField
          label="Fecha de inicio"
          name="FechaInicia"
          value={values.FechaInicia}
          onChange={handleChange}
          fullWidth
          type='date'
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.FechaInicia)}
          helperText={errors.FechaInicia}
        />
        <TextField
          label="Fecha de fin"
          name="FechaTermina"
          value={values.FechaTermina}
          onChange={handleChange}
          fullWidth
          type='date'
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.FechaTermina)}
          helperText={errors.FechaTermina}
        />
        <SelectFrente
          multiple
          value={filtros.frentes}
          onChange={e => setFiltros({ ...filtros, frentes: e.target.value })}
        />
        <SelectMaquinariaGIO
          multiple
          label="Codigo de maquinaria"
          frentes={filtros.frentes}
          value={filtros.codigo}
          onChange={(ev, val) => setFiltros({ ...filtros, maquinaria: val })}
        />
        {/* <SelectTipoMaq
          multiple
          value={filtros.tipo_maquinaria}
          onChange={value => setFiltros({ ...filtros, tipo_maquinaria: value })}
          nullable
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={saving}
          onClick={handleSave}
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </>
  )
}

export default ExportacionCrear;
