import React from 'react'
import {
  IconButton,
  makeStyles
} from '@material-ui/core'
import { DataGrid } from 'devextreme-react'
import { Column, SearchPanel, FilterRow, HeaderFilter } from 'devextreme-react/data-grid'
import { Delete, Edit, Alarm, Tune } from '@material-ui/icons'

const MaquinariaTabla = ({ items, onDelete, onEdit, onShowVariables, onEditVariables }) => {
  const CellOpciones = (dataCell) => {
    return <div>
      <IconButton onClick={() => onEdit(dataCell.data.IdMaquinaria)}>
        <Edit />
      </IconButton>
      <IconButton onClick={() => onDelete(dataCell.data.IdMaquinaria)}>
        <Delete />
      </IconButton>
      <IconButton onClick={() => onShowVariables(dataCell.data.IdMaquinaria)}>
        <Alarm />
      </IconButton>
      <IconButton onClick={() => onEditVariables(dataCell.data.IdMaquinaria)}>
        <Tune />
      </IconButton>
    </div>
  }
  return <DataGrid
    dataSource={items}
    showBorders
    showColumnLines
    rowAlternationEnabled
    allowColumnResizing
    height='80vh'
  >
    <FilterRow visible={ true} />
    <HeaderFilter visible={true} />
    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Buscar" />
    {/* <Column caption="Id" dataField="IdMaquinaria" /> */}
    <Column caption="Codigo" dataField="Codigo" />
    <Column caption="Marca" dataField="Marca" />
    <Column caption="Modelo" dataField="Modelo" />
    <Column caption="Serie" dataField="Serie" />
    <Column caption="Área agrícola" dataField="Area" />
    <Column caption="Tipo de maquinaria" dataField="TipoMaquinaria" />
    <Column caption="Codigo de GIO" dataField="CodigoGIO" />
    <Column caption="Frente" dataField="Frente" />
    <Column caption="Frente operativo" dataField="FrenteOperativo" />
    <Column caption="Opciones" width={200} cellRender={CellOpciones} />
  </DataGrid>
}

export default MaquinariaTabla
